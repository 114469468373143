
  .switchFilter {
    width: 0;
    display: inline-block;
    background-color: #37ada7;
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    -webkit-transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
    -moz-transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
    transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
  }

  .switchFilter1 {
    width: 0;
    display: inline-block;
    background-color: #37ada7;
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    -webkit-transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
    -moz-transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
    transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
  }

  .switchFilter2 {
    width: 0;
    display: inline-block;
    background-color: #37ada7;
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    -webkit-transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
    -moz-transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
    transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
  }
  

  
  .switch {    
    display: inline-block;    
    padding-top: 13px;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
    margin: 1em 0;
    margin-left: 60px
  }
  .switch input[type="radio"] {
    visibility: hidden;
    position: absolute;
    height: 100%;
  }
  .switch input[type="radio"]#yes:checked ~ label[for="yes"] {
    color: white;
    border: 1px solid #37ada7;
  }
  .switch input[type="radio"]#yes:checked ~ .switchFilter {
    left: 0;
    opacity: 1;
    width: 61px;
    height: 33px;
    margin-top: 13px;
  }
  .switch input[type="radio"]#no:checked ~ label[for="no"] {
    color: white;
    border: 1px solid #37ada7;
  }
  .switch input[type="radio"]#no:checked ~ .switchFilter {
    left: 0;
    opacity: 1;
    width: 61px;
    height: 33px;
    margin-top: 13px;
    margin-left: 60px;
  }


  .switch1 {    
    display: inline-block;    
    padding-top: 13px;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
    margin: 1em 0;
    margin-left: 60px
  }
  .switch1 input[type="radio"] {
    visibility: hidden;
    position: absolute;
    height: 100%;
  }
  .switch1 input[type="radio"]#yes1:checked ~ label[for="yes1"] {
    color: white;
    border: 1px solid #37ada7;
  }
  .switch1 input[type="radio"]#yes1:checked ~ .switchFilter1 {
    left: 0;
    opacity: 1;
    width: 61px;
    height: 33px;
    margin-top: 13px;
  }
  .switch1 input[type="radio"]#no1:checked ~ label[for="no1"] {
    color: white;
    border: 1px solid #37ada7;
  }
  .switch1 input[type="radio"]#no1:checked ~ .switchFilter1 {
    left: 0;
    opacity: 1;
    width: 61px;
    height: 33px;
    margin-top: 13px;
    margin-left: 60px;
  }

  .switch2 {    
    display: inline-block;    
    padding-top: 13px;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
    margin: 1em 0;
    margin-left: 60px
  }
  .switch2 input[type="radio"] {
    visibility: hidden;
    position: absolute;
    height: 100%;
  }
  .switch2 input[type="radio"]#yes2:checked ~ label[for="yes2"] {
    color: white;
    border: 1px solid #37ada7;
  }
  .switch2 input[type="radio"]#yes2:checked ~ .switchFilter2 {
    left: 0;
    opacity: 1;
    width: 61px;
    height: 33px;
    margin-top: 13px;
  }
  .switch2 input[type="radio"]#no2:checked ~ label[for="no2"] {
    color: white;
    border: 1px solid #37ada7;
  }
  .switch2 input[type="radio"]#no2:checked ~ .switchFilter2 {
    left: 0;
    opacity: 1;
    width: 61px;
    height: 33px;
    margin-top: 13px;
    margin-left: 60px;
  }
  
  
  .Info {
    padding: 5px 0px 5px 5px;
    background-color: #f7f7f7;
  }

.toggle{
  position: relative;
  display: inline-block;
  width: 53px;
  height: 33px;
}
.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -7px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #37ada7;
}

input:focus + .slider {
  box-shadow: 0 0 1px #37ada7;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
